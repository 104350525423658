import React, { useState, useEffect } from 'react';
import Page from './Page';
import { pages } from './pages';
import { Container } from 'react-bootstrap';

function App() {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState({});

  useEffect(() => {
    const newPage = pages.find((p) => p.number === currentPageNumber);
    setCurrentPage(newPage);
  }, [currentPageNumber]);


  return (
    <>
      <Container className="d-flex align-items-top justify-content-center mt-5"
        style={{ minHeight: "100vh" }}>
          <div className="w-100" style={{ maxWidth: "600px" }}>
            <h2 className="text-center">Amnesio 3.0</h2>
            <Page page={currentPage} setCurrentPageNumber={setCurrentPageNumber} />
          </div>      
      </Container>
    </>
  );
}

export default App;
