export const pages =  [
    {
        number: 1,
        text: "You wake up in a dumpster without any cloves.",
        choices: [
            {
                description: "Get out of the dumpster and search for cloves",
                target: 2
            },
            {
                description: "Stay in the dumpster",
                target: 3
            }
        ]
    },
    {
        number: 2,
        text: "You get out of the dumpster and you see a pack of cloves on the ground.",
        choices: [
            {
                description: "Pick up pack of cloves",
                target: 4
            },
            {
                description: "Assume the pack is empty and leave it on the ground",
                target: 5
            }
        ]
    },
    {
        number: 3,
        text: "You stay in the dumpster for all of eternity. It seems like you've lost.",
        choices: []
    },
    {
        number: 4,
        text: "You open the pack of cloves and there is one clove left.",
        choices: [
            {
                description: "Smoke the clove",
                target: 6
            },
            {
                description: "Put down the pack and get back in the dumpster",
                target: 1
            },
            {
                description: "Check the time",
                target: 7
            }
        ]
    },
    {
        number: 5,
        text: "The question of whether or not there were any cloves in that pack haunts you for the rest of your life. You lose.",
        choices: []
    },
    {
        number: 6,
        text: "You smoke the clove. And you are victorious! Although maybe you could have been more victorious.",
        choices: []
    },
    {
        number: 7,
        text: "You realize it is 12:41 AM and you are 20 minutes from 101.",
        choices: [
            {
                description: "Give up and smoke the clove",
                target: 6
            },
            {
                description: "Drive at top speed to 101",
                target: 8
            }
        ]
    },
    {
        number: 8,
        text: "You are have 2 minutes to get to 101 but you are stuck at a light.",
        choices: [
            {
                description: "Wait patiently for the light to change",
                target: 9
            },
            {
                description: "Get out of your truck and run!",
                target: 10
            },
            {
                description: "Go home",
                target: 11
            }
        ]
    },
    {
        number: 9,
        text: "You make it to 101 but the lights turn out just as you arrive (seems slightly early??). You smoke your one clove and are partially victorious.",
        choices: []
    },
    {
        number: 10,
        text: "You get to 101 right before it closes and you stock up on cloves. You then smoke cloves all night long. ULTIMATE VICTORY!!",
        choices: []
    },
    {
        number: 11,
        text: "You have given up. You return home and the last clove falls between the seats never to be seen again. You lose.",
        choices: []
    }
];

